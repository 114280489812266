<template>
  <th class="headerColumn" :class="{ sortable: sortable }">
    <span>{{ text }}</span>
    <i v-if="sortable" class="icon">{{ sortIconMap[activeSort] }}</i>
  </th>
</template>

<script>
export default {
  name: 'HeaderColumn',
  data: () => ({
    sortIconMap: {
      asc: 'arrow_downward',
      desc: 'arrow_upward',
      none: 'sort'
    }
  }),
  props: {
    text: {
      type: String,
      default: ''
    },
    sortable: {
      type: Boolean,
      default: false
    },
    activeSort: {
      type: String,
      default: 'none'
    }
  }
};
</script>

<style lang="scss">
.headerColumn {
  position: relative;

  & span {
    user-select: none;
  }

  & i.icon {
    position: absolute;
    top: 12px;
    margin-left: 16px;
  }

  &.sortable {
    cursor: pointer;
  }
}
</style>
