<template>
  <ProgramDropdown
    :loading="loadingAgentSources"
    :agentSources="agentSources"
    :programId="selectedProgramId"
    :agentSourceId="selectedAgentSourceId"
    width="500px"
    @selection="selectionChanged"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAgentStore } from '@/stores';
import ProgramDropdown from './ProgramDropdown.vue';

export default {
  name: 'ProgramDropdownDataHarness',
  emits: ['selectionUpdated'],
  components: {
    ProgramDropdown
  },
  computed: {
    ...mapState(useAgentStore, ['agentSources', 'loadingAgentSources', 'selectedAgentSourceId', 'selectedProgramId'])
  },
  methods: {
    ...mapActions(useAgentStore, ['setSelectedIds', 'fetchAgentSources']),
    selectionChanged(selectedIds) {
      this.setSelectedIds(selectedIds);
      this.$emit('selectionUpdated');
    }
  },
  mounted() {
    if (!this.agentSources) {
      this.fetchAgentSources();
    }
  }
};
</script>
