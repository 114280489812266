import { defineStore } from 'pinia';
import { CoachingMethodService } from '@/services/CoachingMethodService';

export const useCoachingAdminStore = defineStore('coachingAdmin', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    coachingMethodsLoading: false,
    coachingMethods: []
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Gets all coaching methods to display on the admin page.
     * @returns {undefined | Error}
     */
    async getCoachingMethods() {
      this.coachingMethodsLoading = true;
      try {
        const methodsResponse = await CoachingMethodService.fetchCoachingMethods(true);
        this.coachingMethods = methodsResponse.data?.items;
      } catch (error) {
        return error;
      }
      this.coachingMethodsLoading = false;
    },
    /**
     * Creates a coaching method.
     * @param {Object} newCoachingMethod The coaching method to create with an ID of 0.
     * @returns {undefined | Error}
     */
    async createCoachingMethod(newCoachingMethod) {
      try {
        const newMethodResponse = await CoachingMethodService.createCoachingMethod(newCoachingMethod);
        this.coachingMethods.unshift(newMethodResponse.data);
      } catch (error) {
        return error;
      }
    },
    /**
     * Reactivates the coaching method that matches the text of coachingMethod.description.
     * @param {Object} coachingMethod The coaching method to reactivate.
     * @returns {undefined | Error}
     */
    async reactivateCoachingMethod(coachingMethod) {
      try {
        // Need to set the id to 0 for the backend to to reactivate an existing coaching method with matching description. ¯\_(ツ)_/¯
        await CoachingMethodService.createCoachingMethod({
          ...coachingMethod,
          coachingMethodId: 0
        });
        this.coachingMethods.find(
          method => method.coachingMethodId === coachingMethod.coachingMethodId
        ).isActive = true;
      } catch (error) {
        return error;
      }
    },
    /**
     * Deactivates a coaching method and modifies state accordingly.
     * @param {number} coachingMethodId The id of the coaching method to deactivate.
     * @returns
     */
    async deactivateCoachingMethod(coachingMethodId) {
      try {
        await CoachingMethodService.deactivateCoachingMethod(coachingMethodId);
        this.coachingMethods.find(method => method.coachingMethodId === coachingMethodId).isActive = false;
      } catch (error) {
        return error;
      }
    }
  }
});
