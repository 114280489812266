import { defineStore } from 'pinia';
import { useAuthStore, useAgentStore } from '.';
import { AssessmentService } from '@/services/AssessmentService';

export const useDraftsStore = defineStore('drafts', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    loadingDrafts: false,
    drafts: []
  }),

  /*************************************************
   * GETTERS
   *************************************************/
  getters: {
    dashboardDrafts: ({ drafts }) => drafts.slice(0, 2)
  },

  /*************************************************
   * ACTIONS
   *************************************************/

  /**
   * Gets all drafts for the logged in user.
   */
  actions: {
    async getDrafts(pageNumber, pageSize) {
      this.loadingDrafts = true;
      const { memberId } = await useAuthStore().getLoggedInUser();
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      return AssessmentService.getDrafts(memberId, selectedAgentSourceId, selectedProgramId, pageNumber, pageSize)
        .then(response => {
          this.drafts = response.data?.items;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        })
        .finally(() => {
          this.loadingDrafts = false;
        });
    }
  }
});
