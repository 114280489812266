<template>
  <tr class="tpgTable__pagination">
    <td :colspan="colspan">
      <PaginationControls
        v-model:selectedPageSize="pageSize"
        v-bind="tableState"
        @firstPage="goToPage(1)"
        @previousPage="previousPage"
        @nextPage="nextPage"
        @lastPage="goToPage(tableState.totalPages)"
        @goToPage="goToPage($event)"
      />
    </td>
  </tr>
</template>

<script>
import { PaginationControls } from 'vue-components';
import { mapActions, mapState } from 'pinia';
import { useAgentStore } from '@/stores';

export default {
  name: 'AgentTablePagination',
  components: {
    PaginationControls
  },
  inject: ['notify'],
  props: {
    colspan: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapState(useAgentStore, { tableState: 'agentTableState' }),
    pageSize: {
      get() {
        return this.tableState.pageSize;
      },
      set(value) {
        this.updatePageSize(value).catch(error => {
          this.notify.error(error);
        });
      }
    }
  },
  methods: {
    ...mapActions(useAgentStore, {
      updatePageSize: 'updatePageSize',
      fetchPreviousPage: 'previousPage',
      fetchNextPage: 'nextPage',
      goToPage: 'goToPage'
    }),
    previousPage() {
      this.fetchPreviousPage().catch(error => {
        this.notify.error(error);
      });
    },
    nextPage() {
      this.fetchNextPage().catch(error => {
        this.notify.error(error);
      });
    }
  }
};
</script>
