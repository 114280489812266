<template>
  <div class="tapeDeck">
    <div
      class="tapeDeck__button"
      v-for="option in options"
      :key="option.value"
      :class="{ selected: modelValue === option.value }"
      @click="selectOption(option)"
    >
      {{ option.display }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  /**
   * { display: string, value: string }[]
   */
  options: {
    type: Array,
    default: () => [
      { display: 'Option 1', value: 'option1' },
      { display: 'Option 2', value: 'option2' }
    ]
  },
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);

const selectOption = option => {
  if (props.modelValue !== option.value) {
    emit('update:modelValue', option.value);
  }
};
</script>

<style lang="scss">
.tapeDeck {
  display: flex;
  align-items: center;

  &__button {
    padding: 6px 16px;
    background-color: var(--white);
    color: var(--black-trans);
    transition: 0.1s linear;
    font-size: var(--font-size-small);
    border: var(--border-subtle);
    cursor: pointer;

    &.selected {
      background-color: var(--accent-light);
      color: white;
      border: var(--accent-light) 1px solid;
      transition: 0.1s linear;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}
</style>
