<template>
  <PageBlockLoader v-model="showLoader" />
</template>

<script setup>
import { PageBlockLoader } from 'vue-components';
import { useAssessmentStore, useInteractionStore } from '@/stores';
import { computed } from 'vue';

const assessmentStore = useAssessmentStore();
const interactionStore = useInteractionStore();

const showLoader = computed(() => {
  return (
    assessmentStore.savingAssessment ||
    assessmentStore.startingAssessment ||
    interactionStore.loadingInteractions ||
    interactionStore.loadingInteractionScore
  );
});
</script>
