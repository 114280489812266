<template>
  <div class="page people">
    <div class="page__programSelect">
      <span>Showing agents for:</span>
      <ProgramDropdownDataHarness />
    </div>
    <h1>My People</h1>
    <h5>Select someone to build out a development session</h5>
    <div class="people__actions">
      <SearchInput class="people__searchField" v-model="searchString" placeholder="Search for a person" />
      <SupervisorFilter @selectionUpdated="filterBySupervisor" />
    </div>
    <AgentTable class="people__table" @viewAgent="goToAgent" @startAssessment="goToAssessment" />
  </div>
</template>

<script>
import { SearchInput } from 'vue-components';
import { AgentTable, SupervisorFilter } from '@/components/AgentTable';
import { mapActions, mapState } from 'pinia';
import { useDocStore, useAgentStore } from '@/stores';
import { ProgramDropdownDataHarness } from '@/components';

export default {
  // eslint-disable-next-line
  name: 'People',
  inject: ['notify'],
  components: {
    SearchInput,
    AgentTable,
    SupervisorFilter,
    ProgramDropdownDataHarness
  },
  computed: {
    ...mapState(useAgentStore, ['agentTableState']),
    searchString: {
      get() {
        return this.agentTableState.searchString;
      },
      set(value) {
        this.updateSearchString(value).catch(error => {
          this.notify.error(error.message);
        });
      }
    }
  },
  mounted() {
    this.updateDocTitle('People');
  },
  methods: {
    ...mapActions(useDocStore, ['updateDocTitle']),
    ...mapActions(useAgentStore, ['updateSearchString', 'filterBySupervisor']),
    goToAssessment(agentId) {
      this.$router.push({ name: 'PerformAssessment', params: { agentId } });
    },
    goToAgent(agentId) {
      this.$router.push({ name: 'Agent', params: { agentId } });
    }
  }
};
</script>

<style lang="scss">
.people {
  &__actions {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__searchField {
    width: 50%;
  }

  &__table {
    margin-top: 40px;
  }
}
</style>
