<template>
  <div v-if="loading" class="programDropdown__placeholder" :style="`width:${width}`">
    <span>Loading...</span>
    <i class="icon">arrow_drop_down</i>
  </div>
  <TpgSelect
    v-else-if="agentSources"
    v-model="selectedProgram"
    :options="agentSources"
    optionLabel="path"
    groupOptions="programs"
    groupLabel="path"
    placeholder="Select a program"
    :width="width"
    :allowGroupRowSelection="false"
    :includeGroupWithSelection="true"
    data-cy="agent-source-dropdown"
  />
</template>

<script>
import { TpgSelect } from 'vue-components';

export default {
  name: 'ProgramDropdown',
  components: {
    TpgSelect
  },
  props: {
    loading: Boolean,
    agentSources: Array,
    programId: Number,
    agentSourceId: Number,
    width: {
      type: String,
      default: '100%'
    }
  },
  emits: ['selection'],
  computed: {
    selectedProgram: {
      get() {
        if (this.agentSources && this.agentSourceId && this.programId) {
          const localId = `${this.agentSourceId}-${this.programId}`;
          const selectedAgentSource = this.agentSources.find(source => source.agentSourceId === this.agentSourceId);
          return selectedAgentSource.programs.find(program => program.localId === localId);
        } else {
          return null;
        }
      },
      set(program) {
        const programId = program ? program.programId : null;
        const agentSourceId = program ? program.parent.agentSourceId : null;
        this.$emit('selection', { agentSourceId, programId });
      }
    }
  }
};
</script>

<style lang="scss">
.programDropdown__placeholder {
  position: relative;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  border: var(--sel-field-border);
  background-color: var(--white);
  color: #adadad;

  & span {
    margin-left: 16px;
  }

  & i.icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
