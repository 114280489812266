<template>
  <div class="addOutcome">
    <i class="icon">add_circle</i>
    <span>Add Outcome</span>
  </div>
</template>

<script>
export default {
  name: 'AddOutcomeButton'
};
</script>

<style lang="scss">
.addOutcome {
  height: var(--outcome-card-height);
  padding: 24px;
  border-radius: var(--outcome-card-border-radius);
  border: var(--outcome-add-border);
  background-color: var(--outcome-add-bg-color);
  color: var(--outcome-text-color-trans);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    border: var(--outcome-add-border-hover);
    color: var(--outcome-text-color);
    transition: 0.2s;
  }

  & i.icon {
    margin-right: 4px;
  }
}
</style>
