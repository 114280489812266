import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

/**
 * Service to interact with coaching session the api.
 */
export class AssessmentService {
  /**
   * Gets assessments by agent source.
   * @param {number} agentSourceId Agent source ID.
   * @param {number} programId The selected program Id.
   * @param {number} pageNumber The page number for the paginated list of results.
   * @param {number} pageSize The number of items for each page.
   * @returns {AxiosResponse}
   */
  static getAssessmentsByAgentSource(agentSourceId, programId, pageNumber, pageSize) {
    return http.get(URLS.ASSESSMENT, { params: { agentSourceId, programId, pageNumber, pageSize } });
  }

  /**
   * Sends a completed assessment (coaching session) to be saved in the DB.
   * @param {Object} assessment The assessment or coachingSession object to save.
   * @returns {AxiosResponse} The saved coaching session.
   */
  static saveAssessment(assessment) {
    return http.post(URLS.ASSESSMENT, assessment);
  }

  /**
   * Gets a paginated list of completed coaching sessions for an agent.
   * @param {number} agentId Agent ID.
   * @param {number} pageNumber The page number for the paginated list of results.
   * @param {number} pageSize The number of items for each page.
   * @returns {AxiosResponse}
   */
  static getCoachingHistoryByAgent(agentSourceId, programId, agentId, pageNumber, pageSize) {
    return http.get(URLS.ASSESSMENT, { params: { agentSourceId, programId, agentId, pageNumber, pageSize } });
  }

  /**
   * Gets an assessment (coaching session) by its ID.
   * @param {number} coachingSessionId The ID of the coaching session to fetch.
   * @returns
   */
  static getAssessmentById(coachingSessionId) {
    return http.get(`${URLS.ASSESSMENT}/${coachingSessionId}`);
  }

  /**
   * Gets a user's draft coaching sessions.
   * @param {number} memberId The member id of the user.
   * @param {number} pageNumber The page number for the paginated list of results.
   * @param {number} pageSize The number of items for each page.
   */
  static getDrafts(memberId, agentSourceId, programId, pageNumber, pageSize) {
    return http.get(URLS.DRAFT, { params: { memberId, agentSourceId, programId, pageNumber, pageSize } });
  }

  /**
   * Deletes an assessment (coaching session) draft by its ID.
   * @param {number} coachingSessionId The ID of the coaching session draft to delete.
   */
  static deleteDraft(coachingSessionId) {
    const endpoint = `${URLS.ASSESSMENT}/${coachingSessionId}/Draft`;
    return http.delete(endpoint);
  }
}
