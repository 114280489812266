export const URLS = {
  /**
   * COACHING SESSION or ASSESSMENT
   */
  ASSESSMENT: 'CoachingSession',
  DRAFT: 'CoachingSession/Draft',

  /**
   * AGENT
   */
  AGENT: 'Agent',
  SUPERVISORS: 'Agent/Supervisor',
  AGENT_SOURCE: 'AgentSource',

  /**
   * EVALUATION
   */
  EVALUATION: 'Evaluation',

  /**
   * COACHING METHOD
   */
  COACHING_METHOD: 'CoachingMethod',

  /**
   * BEHAVIORS
   */
  BEHAVIOR: 'Behavior',
  FOCUS_BEHAVIOR: 'Behavior/Focus',
  SITE_FOCUS_BEHAVIOR: 'Behavior/SiteFocus',
  CALL_OUTCOME: 'Behavior/CallOutcome',

  /**
   * MEDIA
   */
  MEDIA: 'Media',
  MEDIA_STREAM: '/MediaServices/StreamFileById',

  /**
   * TRENDS
   */
  TOTAL_SESSIONS: '/DevelopTrend/TotalSessions',
  TOTAL_AGENTS: '/DevelopTrend/TotalAgentsCoached',
  TOTAL_TIME: '/DevelopTrend/TotalTimeCoaching',
  COACHING_METHOD_USAGE: '/DevelopTrend/TotalCoachingMethodsUsed',
  COACHING_COMPLETED_BY: '/DevelopTrend/TotalUserSessionCount',
  BEHAVIORS_COACHED: '/DevelopTrend/TotalCoachingBehaviorsUsed'
};
