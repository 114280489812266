<template>
  <div v-for="i in [1, 2, 3]" :key="i" class="evalCardLoading">
    <div class="rect"></div>
    <div class="rect"></div>
    <div class="rect"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.evalCardLoading {
  padding: 16px 16px 16px 20px;
  border: var(--elevated-element-border);
  border-radius: 4px;
  background-color: var(--white);
  margin-bottom: 4px;
  // Full width - padding left and right
  width: calc(100% - 36px);

  & .rect {
    height: 18px;
    margin: 5px 8px;
    background: linear-gradient(to right, #ddd 20%, #eee 50%, #ddd 80%);
    animation: shiny-rect 4s linear infinite;

    &:nth-child(1) {
      width: 500px;
    }

    &:nth-child(2) {
      width: 600px;
    }

    &:nth-child(3) {
      width: 400px;
    }
  }
}

@keyframes shiny-rect {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
</style>
