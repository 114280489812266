import { defineStore } from 'pinia';
import { InteractionService } from '@/services/InteractionService';

export const useInteractionStore = defineStore('interaction', {
  /*************************************************
   * STATE
   *************************************************/
  state: () => ({
    loadingInteraction: false,
    loadingInteractionScore: false
  }),
  /*************************************************
   * ACTIONS
   *************************************************/
  actions: {
    async getInteractionById(interactionId) {
      let interaction;
      this.loadingInteractions = true;

      try {
        interaction = await InteractionService.getInteractionById(interactionId);
      } finally {
        this.loadingInteractions = false;
      }

      return interaction;
    },
    async getEvaluationByInteractionId(interactionId) {
      let score;
      this.loadingInteractionScore = true;

      try {
        score = await InteractionService.getEvaluationByInteractionId(interactionId);
      } finally {
        this.loadingInteractionScore = false;
      }

      return score;
    }
  }
});
