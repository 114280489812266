<template>
  <!-- <div v-if="!selectedEvals.length" class="selectedEvalDisplay__none">No evaluations are selected.</div> -->
  <div v-if="!selectedEvals.length"></div>
  <div v-else class="selectedEvalDisplay__grid">
    <div v-for="(e, i) in selectedEvals" :key="i" class="selectedEvalDisplay__eval" data-cy="selected-eval-card">
      <div class="selectedEvalDisplay__info">
        <div class="selectedEvalDisplay__dateRow">
          <div class="selectedEvalDisplay__date">{{ prettyDate(e.evaluationEndDateTime) }}</div>
          <div class="selectedEvalDisplay__time">{{ prettyTime(e.evaluationEndDateTime) }}</div>
        </div>
        <EvaluationLink class="selectedEvalDisplay__id" :evalId="e.evaluationId" :deleted="e.deleted" />
      </div>
      <TpgButton icon class="selectedEvalDisplay__remove" @click="$emit('remove', e)" data-cy="selected-eval-">
        <i class="icon">close</i>
      </TpgButton>
    </div>
  </div>
</template>

<script>
import { TpgButton } from 'vue-components';
import { prettyTime, prettyDate } from '@/utils/date-helpers';
import { EvaluationLink } from '@/components';

export default {
  name: 'SelectedEvalDisplay',
  components: {
    TpgButton,
    EvaluationLink
  },
  emits: ['remove'],
  data: () => ({
    prettyTime,
    prettyDate
  }),
  props: {
    selectedEvals: Array
  }
};
</script>

<style lang="scss">
.selectedEvalDisplay {
  &__none {
    font-style: italic;
    text-align: center;
    padding: 27px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
  }

  &__eval {
    background-color: var(--white);
    border-radius: 4px;
    padding: 16px 16px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__remove i.icon {
    color: var(--black-trans-light);
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: var(--font-size-small);
  }

  &__dateRow {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__date {
    font-weight: var(--font-weight-bold);
  }

  &__time {
    font-weight: var(--font-weight-light);
    font-size: 12px;
  }

  &__id {
    color: var(--black-trans);
  }
}
</style>
