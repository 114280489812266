import { defineStore } from 'pinia';
import { EvaluationService } from '@/services/EvaluationService';
import { useAgentStore } from '.';

export const useEvalStore = defineStore('evaluation', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    loadingEvals: false,
    evaluations: null,
    evalListState: {
      agentId: null,
      currentPage: 1,
      pageSize: 5,
      totalPages: 0,
      hasPreviousPage: false,
      hasNextPage: false
    },
    filters: null
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Fetches all evaluations that match the criteria for a given agent.
     * @param {number} agentId The id of the agent to fetch evals for.
     * @param {number} pageNumber The page number of a paginated list of results.
     * @param {number} pageSize The number of query results on each page.
     * @returns Promise
     */
    getEvalsByAgent(agentId, pageNumber, pageSize) {
      this.loadingEvals = true;
      agentId ? (this.evalListState.agentId = agentId) : undefined;
      const agentStore = useAgentStore();

      // Send app selected program Id or a list of user selected program id filters.
      // At minimum the user must have the app program id selected for this call.
      let programIds;
      if (!this.filters.selectedPrograms.length) {
        programIds = [agentStore.selectedProgramId];
      } else {
        programIds = this.filters.selectedPrograms.map(program => program.programId);
      }

      const body = {
        agentId: agentId || this.evalListState.agentId,
        agentSourceId: agentStore.selectedAgentSourceId,
        programIds,
        pageNumber: pageNumber || this.evalListState.currentPage,
        pageSize: pageSize || this.evalListState.pageSize,
        startDateTime: this.filters.startDate.toISOString(),
        endDateTime: this.filters.endDate.toISOString(),
        defectIds: this.filters.selectedBehaviors
          .filter(b => b.defect)
          .map(({ compendiumBehaviorId }) => compendiumBehaviorId),
        nonDefectIds: this.filters.selectedBehaviors
          .filter(b => !b.defect)
          .map(({ compendiumBehaviorId }) => compendiumBehaviorId),
        callOutcomeIds: this.filters.selectedOutcome ? [this.filters.selectedOutcome.compendiumBehaviorId] : []
      };
      return EvaluationService.filteredAgentEvaluations(body)
        .then(response => {
          pageSize ? (this.evalListState.pageSize = pageSize) : undefined;
          const { items, pageIndex, totalPages, hasPreviousPage, hasNextPage } = response.data;
          this.evaluations = items;
          this.evalListState = {
            ...this.evalListState,
            currentPage: pageIndex,
            totalPages,
            hasPreviousPage,
            hasNextPage
          };
        })
        .catch(error => {
          this.evaluations = [];
          throw error?.response?.data?.message || error?.message;
        })
        .finally(() => {
          this.loadingEvals = false;
        });
    },
    /**
     * Initializes evaluation filters.
     */
    initFilters() {
      const agentStore = useAgentStore();
      this.filters = {
        // Init: 2 weeks ago (-14 days)
        startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
        // Init: Today
        endDate: new Date(),
        selectedOutcome: null,
        selectedBehaviors: [],
        selectedPrograms: agentStore.selectedProgram ? [agentStore.selectedProgram] : []
      };
    },
    /**
     * Merges new filters into state and requests evals.
     * @param {Object} filters Filters to apply.
     */
    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      return this.getEvalsByAgent();
    },
    /**
     * Fetches evals by agent given a new page size for the query.
     * @param {number} pageSize The new page size of the query.
     * @returns Promise
     */
    updatePageSize(pageSize) {
      return this.getEvalsByAgent(undefined, 1, pageSize);
    },
    /**
     * Fetches the previous page of results if available.
     * @returns Promise
     */
    previousPage() {
      if (this.evalListState.hasPreviousPage) {
        return this.getEvalsByAgent(undefined, this.evalListState.currentPage - 1);
      }
    },
    /**
     * Fetches the next page of results if available.
     * @returns Promise
     */
    nextPage() {
      if (this.evalListState.hasNextPage) {
        return this.getEvalsByAgent(undefined, this.evalListState.currentPage + 1);
      }
    },
    /**
     * Gets a specific page of results for the current query.
     * @returns {Promise}
     */
    goToPage(pageNumber) {
      return this.getEvalsByAgent(undefined, pageNumber);
    }
  }
});
