<template>
  <BaseDialog v-model="showDialog" v-bind="$attrs" class="filterDialog">
    <template v-slot:title>Select Filters to Apply</template>
    <template v-slot:content>
      <div v-if="filters" class="filterDialog__content">
        <!-- Date Range -->
        <TpgDateRange
          v-model:startDate="filters.startDate"
          v-model:endDate="filters.endDate"
          :startUpperLimit="dateProps.startUpperLimit"
          :endUpperLimit="dateProps.endUpperLimit"
          :inputFormat="dateProps.inputFormat"
        />
        <!-- Call Outcome -->
        <div>
          <h4>Programs</h4>
          <TpgSelect
            v-model="filters.selectedPrograms"
            width="100%"
            :options="selectedSourcePrograms"
            placeholder="Select a program"
            optionLabel="path"
            :displaySelections="false"
            :selectMultiple="true"
            :filterSelectedOptions="true"
          />
          <div
            v-if="filters.selectedPrograms && filters.selectedPrograms.length"
            class="filterDialog__programSelections"
          >
            <div v-for="program in filters.selectedPrograms" :key="program.programId" class="filterDialog__program">
              <div>{{ program.path }}</div>
              <TpgButton icon @click="removeSelectedProgram(program)">
                <i v-if="program.programId !== selectedProgramId" class="icon filterDialog__remove">close</i>
              </TpgButton>
            </div>
          </div>
        </div>
        <!-- Call Outcome -->
        <div>
          <h4>Call Outcome</h4>
          <TpgSelect
            v-model="filters.selectedOutcome"
            width="100%"
            :options="outcomes"
            placeholder="Select an outcome"
            optionLabel="behaviorText"
          />
        </div>
        <!-- Behaviors -->
        <div>
          <h4>Behaviors</h4>
          <TpgSelect
            v-model="filters.selectedBehaviors"
            width="100%"
            :options="behaviors"
            :selectMultiple="true"
            :displaySelections="false"
            :filterSelectedOptions="true"
            placeholder="Select a behavior"
            optionLabel="behaviorText"
          />
          <div
            v-if="filters.selectedBehaviors && !!filters.selectedBehaviors.length"
            class="filterDialog__behaviorSelections"
          >
            <span class="filterDialog__behaviorTitle">Defect</span>
            <span class="filterDialog__behaviorTitle filterDialog__behaviorValue">Behavior</span>
            <span></span>
            <template v-for="behavior in filters.selectedBehaviors" :key="behavior.id">
              <Checkbox
                primary
                class="filterDialog__behaviorCheckbox"
                :checked="behavior.defect"
                @click="toggleBehaviorDefect(behavior)"
              />
              <span class="filterDialog__behaviorValue">{{ behavior.behaviorText }}</span>
              <TpgButton icon @click="removeSelectedBehavior(behavior)">
                <i class="icon filterDialog__remove">close</i>
              </TpgButton>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <TpgButton flat @click="close">Close</TpgButton>
      <TpgButton @click="apply"><i class="icon">library_add_check</i>Apply</TpgButton>
    </template>
  </BaseDialog>
</template>

<script>
import { BaseDialog, TpgButton, TpgSelect } from 'vue-components';
import Checkbox from '@/components/Checkbox.vue';
import TpgDateRange from '@/components/TpgDateRange.vue';
import { mapState } from 'pinia';
import { useCoachingStore, useAgentStore } from '@/stores';

export default {
  name: 'EvalFilterDialog',
  emits: ['applyFilters'],
  components: {
    BaseDialog,
    TpgButton,
    TpgSelect,
    Checkbox,
    TpgDateRange
  },
  data: () => ({
    showDialog: false,
    filters: null,
    dateProps: {
      inputFormat: 'MM/dd/yyyy',
      // Only past dates can be selected since no completed evals will be available for future dates.
      startUpperLimit: new Date(),
      endUpperLimit: new Date()
    }
  }),
  computed: {
    ...mapState(useCoachingStore, {
      outcomes: 'callOutcomes',
      behaviors: store => store.compendiumBehaviors.map(b => ({ ...b, defect: true }))
    }),
    ...mapState(useAgentStore, ['agentSources', 'selectedSourcePrograms', 'selectedProgramId'])
  },
  methods: {
    open(filters) {
      this.filters = { ...filters };
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    reset() {
      this.filters = null;
    },
    apply() {
      this.$emit('applyFilters', { ...this.filters });
      this.reset();
      this.close();
    },
    removeSelectedProgram(program) {
      const index = this.filters.selectedPrograms.findIndex(p => p.programId === program.programId);
      if (index > -1) {
        this.filters.selectedPrograms.splice(index, 1);
      }
    },
    toggleBehaviorDefect(behavior) {
      behavior.defect = !behavior.defect;
    },
    removeSelectedBehavior(behavior) {
      const index = this.filters.selectedBehaviors.findIndex(b => b.behaviorId === behavior.behaviorId);
      if (index > -1) {
        this.filters.selectedBehaviors.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss">
.filterDialog {
  & .dialog__container {
    overflow-y: scroll;
    padding: 32px;
  }

  & .dialog {
    overflow: unset;
    width: 400px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 32px 0 40px 0;
  }

  &__select {
    width: 450px;
  }

  &__programSelections {
    margin: 16px 0;
    padding: 0 16px;
  }

  &__program {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    height: 36px;
  }

  &__behaviors {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  &__behaviorSelections {
    margin: 24px 0;
    display: grid;
    grid-template-columns: 45px 1fr 32px;
    grid-template-rows: auto;
    column-gap: 16px;
    row-gap: 8px;
    align-items: center;
    justify-items: center;
  }

  &__behaviorTitle {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    color: var(--black-trans);
  }

  &__behaviorCheckbox {
    justify-self: center;
    margin-bottom: 0;
  }

  &__behaviorValue {
    justify-self: start;
  }

  &__remove {
    color: var(--black-trans-light) !important;
  }
}
</style>
