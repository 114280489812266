import { defineStore } from 'pinia';
import { TrendService } from '@/services/TrendService';
import { useAgentStore } from '@/stores';

export const useInsightStore = defineStore('insights', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    /** 'last7' | 'last30' */
    timeframe: 'last7',
    /** Init: Today */
    endDate: new Date(new Date().setUTCHours(23, 59, 59, 999)),
    coachingMethodUsageLoading: false,
    coachingMethodUsage: null,

    coachingCompletedByLoading: false,
    coachingCompletedBy: null,

    behaviorsCoachedLoading: false,
    behaviorsCoached: null
  }),

  /*************************************************
   * GETTERS
   *************************************************/

  getters: {
    /**
     * Computed based on user selected timeframe. 7 days ago or 30 days ago.
     */
    startDate: ({ timeframe }) =>
      new Date(
        new Date(new Date().setDate(new Date().getDate() - (timeframe === 'last7' ? 7 : 30))).setUTCHours(0, 0, 0, 0)
      ),
    sortedCoachingMethods: ({ coachingMethodUsage }) => {
      return coachingMethodUsage ? coachingMethodUsage.sort((a, b) => b.count - a.count) : [];
    },
    truncatedCoachingMethods: ({ sortedCoachingMethods }) => sortedCoachingMethods.slice(0, 10),
    sortedCoachingCompletedBy: ({ coachingCompletedBy }) => {
      return coachingCompletedBy ? coachingCompletedBy.sort((a, b) => b.count - a.count) : [];
    },
    truncatedCoachingCompletedBy: ({ sortedCoachingCompletedBy }) => sortedCoachingCompletedBy.slice(0, 10),
    sortedBehaviorsCoached: ({ behaviorsCoached }) => {
      return behaviorsCoached ? behaviorsCoached.sort((a, b) => b.count - a.count) : [];
    },
    truncatedBehaviorsCoached: ({ sortedBehaviorsCoached }) => sortedBehaviorsCoached.slice(0, 10)
  },

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Sets a new value of timeframe state value and triggers a chart refresh.
     * @param {'last7' | 'last30'} timeframe The timeframe to get chart data for.
     */
    setTimeframe(timeframe) {
      this.timeframe = timeframe;
      this.refreshChartData();
    },
    /**
     * Resets all the chart data so we never have stale data when rendering the insights page.
     */
    resetChartData() {
      this.coachingMethodUsageLoading = false;
      this.coachingMethodUsage = null;
      this.coachingCompletedByLoading = false;
      this.coachingCompletedBy = null;
      this.behaviorsCoachedLoading = false;
      this.behaviorsCoached = null;
    },
    /**
     * Refreshes all chart data.
     */
    refreshChartData() {
      this.fetchCoachingMethodUsage();
      this.fetchCoachingCompletedBy();
      this.fetchBehaviorsCoached();
    },
    /**
     * Fetches coaching method usage trend data for the selected program and the selected date range.
     * @returns {Promise}
     */
    fetchCoachingMethodUsage() {
      this.coachingMethodUsageLoading = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      return TrendService.getCoachingMethodUsage(
        selectedAgentSourceId,
        selectedProgramId,
        this.endDate.toISOString(),
        this.startDate.toISOString()
      )
        .then(response => {
          this.coachingMethodUsage = response.data?.items;
        })
        .finally(() => {
          this.coachingMethodUsageLoading = false;
        });
    },
    /**
     * Fetches coaching completed by trend data for the selected program and date range.
     * @returns {Promise}
     */
    fetchCoachingCompletedBy() {
      this.coachingCompletedByLoading = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      return TrendService.getCoachingCompletedBy(
        selectedAgentSourceId,
        selectedProgramId,
        this.endDate.toISOString(),
        this.startDate.toISOString()
      )
        .then(response => {
          this.coachingCompletedBy = response.data?.items;
        })
        .finally(() => {
          this.coachingCompletedByLoading = false;
        });
    },
    /**
     * Fetches behaviors coached trend data for the selected program and date range.
     * @returns {Promise}
     */
    fetchBehaviorsCoached() {
      this.behaviorsCoachedLoading = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      return TrendService.getBehaviorsCoached(
        selectedAgentSourceId,
        selectedProgramId,
        this.endDate.toISOString(),
        this.startDate.toISOString()
      )
        .then(response => {
          this.behaviorsCoached = response.data?.items;
        })
        .finally(() => {
          this.behaviorsCoachedLoading = false;
        });
    }
  }
});
