import { defineStore } from 'pinia';
import { DEFAULT_CONFIRMATION } from '@/resources';

export const useConfirmDialogStore = defineStore('confirmDialog', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    show: false,
    /**
     * Use Constants from @/resources/confirm-dialog-config.js
     * EX:
     *  title: 'Confirm Action',
     *  message: 'Are you sure you want to perform this action?',
     *  cancelText: 'Cancel',
     *  confirmText: 'Confirm',
     *  confirmButtonStyle: ''
     */
    options: {},
    resolvePromise: undefined
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Opens the confirm dialog with the passed options.
     * @param {Object} opts Configuration options for the dialog. Use constants from @/resources/confirm-dialog-config.js
     */
    open(opts) {
      this.options = { ...DEFAULT_CONFIRMATION, ...opts };
      this.show = true;
      return new Promise(resolve => {
        this.resolvePromise = resolve;
      });
    },
    /**
     * Confirm the action.
     */
    confirm() {
      this.show = false;
      this.resolvePromise(true);
    },
    /**
     * Cancel the action.
     */
    cancel() {
      this.show = false;
      this.resolvePromise(false);
    }
  }
});
