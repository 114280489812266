import { interpolateRgbBasis } from 'd3-interpolate';

export const brandColors = [
  '#BAD589',
  '#E0836D',
  '#FF7474',
  '#517FC8',
  '#79A7E7',
  '#F0CD83',
  '#485974',
  '#355689',
  '#BAD5FF',
  '#FFA73F',
  '#83D0BE',
  '#A3FFC8',
  '#2865C5',
  '#386FDA'
];

export const chartColors = (datasetLength = 14) => {
  if (datasetLength <= 14) {
    return brandColors;
  } else {
    const colors = [];
    const scale = interpolateRgbBasis(brandColors);
    for (let i = 0; i < datasetLength; i++) {
      colors.push(scale(i / (datasetLength - 1)));
    }
    return colors;
  }
};
