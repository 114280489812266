<template>
  <div>
    <h2>Recent Activity</h2>
    <div v-if="loadingRecentActivity" class="recentActivity__assessments">
      <AssessmentCardLoading v-for="i in [1, 2, 3]" :key="i" />
    </div>
    <template v-else>
      <div v-if="!!recentActivity.length" class="recentActivity__assessments">
        <AssessmentCard v-for="(assessment, i) in recentActivity" :key="i" :assessment="assessment" />
      </div>
      <h5 v-else class="recentActivity__none">
        <span>Recent activity will appear here. There are currently no completed assessments to display.</span>
        <img :src="require('@/assets/RecentActivityPlaceholder.svg')" />
        <div class="recentActivity__blob green"></div>
        <div class="recentActivity__blob yellow"></div>
      </h5>
    </template>
  </div>
</template>

<script>
import AssessmentCard from './AssessmentCard.vue';
import AssessmentCardLoading from './AssessmentCardLoading.vue';
import { mapState, mapActions } from 'pinia';
import { useDashboardStore } from '@/stores';

export default {
  name: 'RecentActivity',
  components: {
    AssessmentCard,
    AssessmentCardLoading
  },
  computed: {
    ...mapState(useDashboardStore, ['recentActivity', 'loadingRecentActivity'])
  },
  methods: {
    ...mapActions(useDashboardStore, ['getRecentActivity'])
  },
  mounted() {
    this.recentActivity.length ? undefined : this.getRecentActivity();
  }
};
</script>

<style lang="scss">
.recentActivity {
  &__assessments {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
  }

  &__none {
    position: relative;
    z-index: 2;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  &__blob {
    position: absolute;
    z-index: 1;
    height: 400px;
    width: 400px;
    border-radius: 200px;
    opacity: 0.2;
    filter: blur(100px);

    &.yellow {
      background-color: #f0cd83;
      left: 10%;
      top: -20%;
    }

    &.green {
      background-color: #bad589;
      right: 15%;
    }
  }
}
</style>
