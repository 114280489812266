<template>
  <TrendCardLoading v-if="totalAgentsCoachedLoading" />
  <TrendCard v-else>
    <template v-slot:timeFrame>This Month</template>
    <template v-slot:metric>{{ totalAgentsCoached?.trend }}</template>
    <template v-slot:description>People coached</template>
  </TrendCard>
</template>

<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTrendStore } from '@/stores';
import TrendCard from './TrendCard.vue';
import TrendCardLoading from './TrendCardLoading.vue';

const { totalAgentsCoached, totalAgentsCoachedLoading } = storeToRefs(useTrendStore());
const { getTotalAgentsCoached } = useTrendStore();

onMounted(() => {
  if (!totalAgentsCoached.value) {
    getTotalAgentsCoached();
  }
});
</script>
