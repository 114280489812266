<template>
  <div class="outcomeCard">
    <div>
      <i class="icon outcomeCard__icon">{{ outcome.icon }}</i>
    </div>
    <div>
      <div class="outcomeCard__title">{{ outcome.title }}</div>
      <div class="outcomeCard__desc">{{ outcome.desc }}</div>
    </div>
    <div v-if="allowRemove" class="outcomeCard__actions">
      <i class="icon" @click="$emit('remove', outcome.id)">close</i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OutcomeCard',
  emits: ['remove'],
  props: {
    outcome: Object,
    allowRemove: Boolean
  }
};
</script>

<style lang="scss">
.outcomeCard {
  display: flex;
  align-items: center;
  background-color: var(--outcome-add-bg-color);
  border-radius: var(--outcome-card-border-radius);
  padding: 16px 32px 16px 16px;
  gap: 16px;
  position: relative;
  transition: 0.2s;

  &:hover .outcomeCard__actions {
    opacity: 1;
    transition: opacity 0.2s linear;
  }

  &.selected {
    background-color: var(--outcome-bg-color);
    transition: 0.2s;

    &__icon,
    &__title {
      color: var(--outcome-text-color);
      transition: 0.2s;
    }
  }

  &__icon {
    color: var(--outcome-text-color);
  }

  &__title {
    color: var(--outcome-text-color);
    font-weight: var(--font-weight-bold);
    margin-bottom: 8px;
  }

  &__actions {
    cursor: pointer;
    position: absolute;
    width: 160px;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
    background: linear-gradient(to left, var(--outcome-bg-color), var(--transparent));
    color: var(--outcome-text-color);

    & i.icon {
      position: absolute;
      right: 16px;
      top: calc(50% - 12px);
    }
  }
}
</style>
