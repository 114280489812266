import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

/**
 * Class with static methods to access agent related API endpoints.
 */
export class AgentService {
  /**
   * Retreives a list of agent sources with nested programs that the logged in user has access to.
   * @param {number} memberId The member ID of the logged in user.
   * @returns
   */
  static getAgentSources(memberId) {
    return http.get(URLS.AGENT_SOURCE, { params: { memberId } });
  }

  /**
   * Get a paginated list of agents for a given agent source.
   * @param {number} agentSourceId The user selected agent source id.
   * @param {number} programId The user selected program id.
   * @param {number} pageNumber The page in the list of paginated results to fetch.
   * @param {number} pageSize The number of results to return in the query.
   * @param {string} searchString A search string to apply to the query.
   * @param {'name' | 'lastAssessed' | 'nextAssessed'} sortOrder Sort order for the query.
   * @param {boolean} asc ASC or DESC order for sorting.
   * @param {number} parentAgentId The agent id of the supervisor to filter agents by.
   * @returns {AxiosResponse}
   */
  static getAgents(agentSourceId, programId, pageNumber, pageSize, searchString, sortOrder, asc, parentAgentId) {
    const params = { agentSourceId, programId, pageNumber, pageSize, sortOrder, asc, parentAgentId };
    searchString ? (params.searchString = searchString) : undefined;
    return http.get(URLS.AGENT, { params });
  }

  /**
   * Gets a single agent with the given agent id.
   * @param {number} agentId The id of the agent to fetch.
   * @returns {AxiosResponse}
   */
  static getAgent(agentId) {
    return http.get(`${URLS.AGENT}/${agentId}`);
  }

  /**
   * Gets the supervisors for a given agent source.
   * @param {number} agentSourceId The id of the agent source to get supervisors for.
   * @param {number} programId The id of the program to get supervisors for.
   * @returns {AxiosResponse}
   */
  static getSupervisors(agentSourceId, programId) {
    return http.get(URLS.SUPERVISORS, { params: { agentSourceId, programId } });
  }
}
