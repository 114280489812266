<template>
  <span v-if="filter" class="filterChip" :class="{ longer: filter.type === 'Date Range' }" :title="tooltip">
    <span v-if="filter.type === 'Date Range'" class="filterChip__iconSpan"><i class="icon" small>date_range</i></span>
    <span v-if="filter.type === 'Call Outcome'" class="filterChip__outcome"><strong>CO</strong></span>
    <span v-if="filter.type === 'Behavior' && filter.defect" class="filterChip__defect">D</span>
    <span class="filterChip__text">{{ filter.behaviorText || filter.text }}</span>
  </span>
  <span v-else class="filterChip">{{ more }} more</span>
</template>

<script>
export default {
  name: 'AppliedFilterChip',
  props: {
    filter: Object,
    more: Number
  },
  computed: {
    tooltip() {
      return this.filter
        ? `${this.filter.defect ? '*Defect*' : ''} ${this.filter.type}: ${this.filter.behaviorText || this.filter.text}`
        : '';
    }
  }
};
</script>

<style lang="scss">
.filterChip {
  color: var(--black);
  border-radius: 4px;
  padding: 8px 16px;
  background-color: #bad5ff;
  max-width: 105px;
  cursor: default;
  display: flex;
  gap: 4px;
  align-items: center;
  max-height: 33px;
  cursor: pointer;

  &.longer {
    max-width: 200px;
  }

  & span {
    height: 17px;
  }

  & i.icon {
    font-size: 17px;
    height: 17px;
    line-height: 17px;
    width: 17px;
  }

  &__outcome,
  &__defect {
    color: var(--white);
    font-weight: var(--font-weight-bold);
    font-size: 12px;
    line-height: 18px;
    background-color: var(--accent);
    padding: 1px 4px;
    border-radius: 4px;
    text-align: center;
  }

  &__outcome {
    background-color: var(--black-trans-light);
  }

  &__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
