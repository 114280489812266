<template>
  <div class="raLoading" data-cy="assessment-card-loading">
    <div>
      <div class="raLoading__top">
        <div class="round--small"></div>
        <div class="rect">Completed Placeholder</div>
      </div>
      <div class="rect middle">General Skill Placeholder</div>
      <div class="rect bottom">XX/XX/XXXX</div>
    </div>
    <div class="round">PH</div>
  </div>
</template>

<script>
export default {
  name: 'AssessmentCardLoading'
};
</script>

<style lang="scss" scoped>
.raLoading {
  box-shadow: var(--elevation-low);
  border-radius: 6px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  background-color: var(--white);
  border: var(--border-subtle);

  &__top {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.rect {
  height: 18px;
  background: linear-gradient(to right, #ddd 20%, #eee 50%, #ddd 80%);
  animation: shiny-rect 4s linear infinite;
  color: transparent;

  &.middle {
    margin-top: 8px;
    margin-bottom: 10px;
  }

  &.bottom {
    margin-bottom: 6px;
  }
}

.round {
  height: var(--avatar-size-small);
  width: var(--avatar-size-small);
  border-radius: var(--avatar-radius-small);
  border: 0.5px solid #ccc;
  background-color: #ddd;
  color: transparent;
}

.round--small {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: #ddd;
  color: transparent;
}

@keyframes shiny-rect {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
</style>
