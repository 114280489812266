<template>
  <div class="notFound">
    <h1>404 Not Found</h1>
    <p>Uh oh! That page doesn't exist.</p>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useDocStore } from '@/stores';

export default {
  name: 'NotFound',
  mounted() {
    this.updateDocTitle('Not Found');
  },
  methods: {
    ...mapActions(useDocStore, ['updateDocTitle'])
  }
};
</script>

<style lang="scss">
.notFound {
  text-align: center;
}
</style>
