<template>
  <!-- Wrapper -->
  <div class="cts__wrapper">
    <!-- Input and Menu Trigger -->
    <div class="cts" :class="{ opened: open }" @click="toggleMenu" data-cy="individual-focus-select">
      <i class="icon">add</i>
      <input
        v-model="searchText"
        class="cts__input"
        ref="search"
        type="text"
        placeholder="Find or create an individual focus"
        @click.stop
      />
      <i v-show="!!searchText" class="icon cts__clearInput" @click.stop="clearSearchText">close</i>
      <i class="icon cts__arrow">arrow_drop_down</i>
    </div>

    <!-- Menu -->
    <transition name="fade">
      <div v-show="open" class="cts__menu">
        <div v-if="searchText && !isExactMatch" class="cts__createNew" @click="newGeneralSkill">
          Click here to create a new general focus area.
        </div>
        <template v-if="filteredOptions.length">
          <!-- Group Row -->
          <div v-for="(group, i) in filteredOptions" :key="'group' + i">
            <div class="cts__row group">{{ group.group }}</div>

            <!-- If no general focus areas -->
            <template v-if="group.group === 'General Focus Area' && !group.options.length">
              <div class="cts__row--noOptions">No general focus areas to display.</div>
            </template>

            <!-- Option Row -->
            <template v-for="(option, j) in group.options" :key="'option' + j">
              <div class="cts__row option" @click="optionClickHandler(option)" data-cy="individual-focus-option">
                {{ option.behaviorText || option.description }}
              </div>
            </template>
          </div>
        </template>
        <div v-if="!filteredOptions.length && isExactMatch" class="cts__placeholder">
          This option is already selected.
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CoachingTargetSelect',
  props: {
    options: Array,
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'newGeneralSkill'],
  data: () => ({
    searchText: '',
    open: false
  }),
  computed: {
    selectedIds() {
      return this.modelValue.map(m => m.behaviorId || m.generalSkillId || JSON.stringify(m));
    },
    filteredOptions() {
      const query = this.searchText.toLowerCase().trim();
      const filteredGroups = [];
      this.options.forEach(group => {
        const filteredOptions = group.options.filter(option => {
          const id = option.behaviorId || option.generalSkillId || JSON.stringify(option);
          const text = option.behaviorText || option.description;
          // Filter out selected options and options that do no match the search string.
          return this.selectedIds.includes(id) ? false : text.toLowerCase().indexOf(query) > -1;
        });
        if (!query || filteredOptions.length) {
          filteredGroups.push({
            ...group,
            options: filteredOptions
          });
        }
      });
      return filteredGroups;
    },
    isExactMatch() {
      const flatOptions = this.filteredOptions.reduce((allOptions, group) => [...allOptions, ...group.options], []);
      return (
        flatOptions.some(val => val.behaviorText === this.searchText || val.description === this.searchText) ||
        this.modelValue.some(val => val.behaviorText === this.searchText || val.description === this.searchText)
      );
    }
  },
  methods: {
    toggleMenu() {
      if (this.open) {
        this.close();
      } else {
        this.open = true;
      }
      if (this.open) {
        this.$nextTick(() => this.$refs.search.focus());
      }
    },
    close() {
      this.open = false;
    },
    optionClickHandler(option) {
      this.$emit('update:modelValue', [...this.modelValue, option]);
      this.close();
      this.clearSearchText();
    },
    newGeneralSkill() {
      const generalSkill = { generalSkillId: null, description: this.searchText };
      this.$emit('newGeneralSkill', generalSkill);
      this.optionClickHandler(generalSkill);
    },
    clearSearchText() {
      this.searchText = '';
    },
    bodyEventListener() {
      const classes = event.target.classList;
      if (!classes.contains('cts') && !classes.contains('cts__row')) {
        this.close();
      }
    }
  },
  mounted() {
    document.body.addEventListener('click', this.bodyEventListener);
  },
  unmounted() {
    document.body.removeEventListener('click', this.bodyEventListener);
  }
};
</script>

<style lang="scss">
.cts__wrapper {
  position: relative;
}

.cts {
  background-color: var(--assess-field-bg-color);
  color: var(--assess-text-accent-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  margin-bottom: 8px;
  position: relative;

  & input {
    background-color: var(--transparent);
    padding: 0 !important;
    border-style: none;
    border-width: 0;
    height: 100%;
    min-width: calc(100% - 36px);
    font-family: var(--font-family-default);
    color: var(--assess-text-accent-color);
    pointer-events: none;

    &::placeholder {
      color: var(--assess-text-accent-color-trans);
    }

    &:focus-visible {
      outline: none;
    }
  }

  &.opened input {
    pointer-events: unset;
  }

  &__clearInput {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 32px;
    color: var(--assess-text-accent-color-trans);
    transition: 0.1s;
    pointer-events: all;
    cursor: pointer;

    &:hover {
      color: var(--assess-text-accent-color);
      transition: 0.1s;
    }
  }

  i.icon.cts__clearInput {
    font-size: 16px;
  }

  &__arrow {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) rotate(0);
    transition: 0.2s;
    pointer-events: none;
    cursor: default;
  }

  &.opened i.icon.cts__arrow {
    transform: translateY(-50%) rotate(180deg);
    transition: 0.2s;
  }

  &__menu {
    position: absolute;
    z-index: 1000;
    top: 32px;
    max-height: 500px;
    width: calc(100% - 2px);
    overflow-y: auto;
    background-color: white;
    border: 1px #d1d5db solid;
    border-radius: 4px;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.05);
  }

  &__row {
    text-align: left;
    padding: 8px 16px;
    transition: background-color 0.1s linear;
    cursor: pointer;

    &:hover {
      background-color: #dcdcdc;
      transition: background-color 0.1s linear;

      &.group {
        background-color: #e3e3e3;
      }
    }

    &.group {
      background-color: #e3e3e3;
      cursor: default;
      pointer-events: none;
    }

    &.option {
      padding: 8px 40px;
    }

    &.selected {
      background-color: #cbddf7;
      transition: background-color 0.1s linear;
    }
  }

  &__createNew {
    text-align: left;
    padding: 16px 40px;
    font-style: italic;
    font-size: var(--font-size-small);
    color: var(--assess-text-color);
    cursor: pointer;
    transition: 0.1s linear;

    &:hover {
      background-color: var(--assess-bg-light);
      transition: background-color 0.1s linear;
    }
  }

  &__placeholder,
  &__row--noOptions {
    padding: 16px 40px;
    font-style: italic;
    font-size: var(--font-size-small);
    color: var(--black-trans-light);
    pointer-events: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
