import { defineStore } from 'pinia';
import { TrendService } from '@/services/TrendService';
import { useAgentStore } from '.';
import { hoursDecimal } from '@/utils/time';

export const useTrendStore = defineStore('trend', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    totalSessionsLoading: false,
    totalSessions: null,
    totalAgentsCoachedLoading: false,
    totalAgentsCoached: null,
    totalTimeCoachingLoading: false,
    totalTimeCoaching: null
  }),

  /*************************************************
   * GETTERS
   *************************************************/

  getters: {
    timeCoachingHours() {
      if (this.totalTimeCoaching) {
        const { days, hours, minutes } = this.totalTimeCoaching.trend;
        return hoursDecimal(days, hours, minutes);
      } else {
        return null;
      }
    }
  },

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    getAllTrends() {
      this.getTotalSessions();
      this.getTotalAgentsCoached();
      this.getTotalTimeCoaching();
    },
    /**
     * Gets the total sessions for this agent source and saves to state.
     * @returns Error or undefined
     */
    async getTotalSessions() {
      this.totalSessionsLoading = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      try {
        const totalSessions = await TrendService.getTotalSessionsTrend(selectedAgentSourceId, selectedProgramId);
        this.totalSessions = totalSessions.data;
      } catch (error) {
        return error;
      }
      this.totalSessionsLoading = false;
    },
    /**
     * Gets the total agents coached this month for this agent source and saves to state.
     * @returns Error or undefined
     */
    async getTotalAgentsCoached() {
      this.totalAgentsCoachedLoading = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      const today = new Date();
      try {
        const totalAgentsCoached = await TrendService.getTotalAgentsCoachedTrend(
          selectedAgentSourceId,
          selectedProgramId,
          today.getMonth() + 1,
          today.getFullYear()
        );
        this.totalAgentsCoached = totalAgentsCoached.data;
      } catch (error) {
        return error;
      }
      this.totalAgentsCoachedLoading = false;
    },
    /**
     * Gets the total time spent coaching this week for this agent source and saves to state.
     * @returns Error or undefined
     */
    async getTotalTimeCoaching() {
      this.totalTimeCoachingLoading = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      try {
        const totalTimeCoaching = await TrendService.getTotalTimeCoachingTrend(
          selectedAgentSourceId,
          selectedProgramId,
          new Date().toISOString()
        );
        this.totalTimeCoaching = totalTimeCoaching.data;
      } catch (error) {
        return error;
      }
      this.totalTimeCoachingLoading = false;
    }
  }
});
