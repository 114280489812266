import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

export class MediaService {
  /**
   * Fetches a media document by id.
   * Includes waveform data.
   * @param {number} documentId The document id of the media to fetch.
   * @returns {AxiosResponse} { data: { result: Media } }
   */
  static fetchMediaById(documentId) {
    return http.get(`${URLS.MEDIA}/${documentId}`);
  }

  /**
   * Fetches the timestamps for a given document ID and returns them.
   * @param {number} interactionId The interaction id of the media to retrieve timestamps for.
   * @returns {AxiosResponse} { data: { result: Timestamp[] } }
   */
  static getMediaTimestamps(interactionId) {
    return http.get(`/timestamps?interactionId=${interactionId}`);
  }

  /**
   * Fetches the Waveform data for a given document ID and returns it.
   * @param {number} documentId The document id of the media to retrieve waveform data for.
   * @returns {AxiosResponse} { data: { result: Waveform[] } }
   */
  static getMediaWaveformData(documentId) {
    return http.get(`/waveform?documentId=${documentId}`);
  }
}
