<template>
  <h2 class="ach__heading">
    <span>Completed Assessments</span>
    <TpgButton id="coachButton" @click="coach"><i class="icon">post_add</i>New Assessment</TpgButton>
  </h2>
  <table class="tpgTable">
    <thead>
      <tr>
        <HeaderColumn text="Date of Assessment" />
        <HeaderColumn text="Coached By" />
        <HeaderColumn text="Coaching Methods" />
      </tr>
    </thead>
    <tbody>
      <TpgTableLoading v-if="loadingCoachingHistory" />
      <template v-else>
        <template v-if="coachingHistory.length">
          <tr
            class="tpgTable__row"
            v-for="(session, i) of coachingHistory"
            :key="i"
            @click="goToAssessmentDetail(session.coachingSessionId)"
          >
            <td class="ach__date">{{ prettyDate(session.coachedDate) }}</td>
            <td class="ach__coach">{{ session.coachedBy }}</td>
            <td class="ach__methods">{{ renderCoachingMethods(session.coachingSessionMethods) }}</td>
          </tr>
        </template>
        <template v-else>
          <tr class="tpgTable__empty">
            <td colspan="5">No results</td>
          </tr>
        </template>
      </template>
    </tbody>
    <tfoot>
      <AgentCoachingHistoryPagination :colspan="3" />
    </tfoot>
  </table>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAgentCoachingHistoryStore } from '@/stores';
import TpgTableLoading from '../TpgTableLoading.vue';
import AgentCoachingHistoryPagination from './AgentCoachingHistoryPagination.vue';
import HeaderColumn from '../HeaderColumn.vue';
import { prettyDate } from '@/utils/date-helpers';
import { TpgButton } from 'vue-components';

export default {
  name: 'AgentCoachingHistory',
  components: {
    TpgTableLoading,
    AgentCoachingHistoryPagination,
    HeaderColumn,
    TpgButton
  },
  data: () => ({
    prettyDate
  }),
  props: {
    agentId: Number
  },
  computed: {
    ...mapState(useAgentCoachingHistoryStore, ['loadingCoachingHistory', 'coachingHistory'])
  },
  watch: {
    agentId(newAgentId) {
      this.getAgentCoachingHistory(newAgentId);
    }
  },
  methods: {
    ...mapActions(useAgentCoachingHistoryStore, ['getAgentCoachingHistory']),
    renderCoachingMethods(coachingSessionMethods) {
      const methods = coachingSessionMethods.map(csm => csm.coachingMethod.description);
      return methods.length < 3 ? methods.join(', ') : `${methods[0]}, ${methods[1]}, and ${methods.length - 2} more`;
    },
    goToAssessmentDetail(coachingSessionId) {
      this.$router.push({ name: 'AssessmentDetail', params: { agentId: this.agentId, coachingSessionId } });
    },
    coach() {
      this.$router.push({ name: 'PerformAssessment', params: { agentId: this.agentId } });
    }
  },
  mounted() {
    this.getAgentCoachingHistory(this.agentId);
  }
};
</script>

<style lang="scss">
.ach {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    width: 30%;
  }

  &__coach {
    width: 30%;
  }

  &__methods {
    width: 40%;
  }
}
</style>
