import { LAUNCHPAD_URL } from '@/application.config';
import { LAUNCHPAD_IFRAME_ID } from '@/resources';
import { crossDomainRequest } from '@/utils/cross-domain-helpers';

/**
 * Class with static methods that access Launchpad to perform auth and logged in user related requests.
 */
export class AuthService {
  /**
   * Get tokens from Launchpad.
   * @returns {Promise} Tokens from Launchpad or null if response takes longer than 10 seconds.
   */
  static getTokens() {
    return crossDomainRequest(LAUNCHPAD_IFRAME_ID, LAUNCHPAD_URL, 'getTokens');
  }

  /**
   * Get logged in user from launchpad.
   * @returns {Promise} Logged in user from Launchpad or null if response takes longer than 10 seconds.
   */
  static getLoggedInUser() {
    return crossDomainRequest(LAUNCHPAD_IFRAME_ID, LAUNCHPAD_URL, 'getLoggedInUser');
  }
}
