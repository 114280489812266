<template>
  <div class="dateRange">
    <div class="dateRange__field">
      <div class="datepicker__field--iconWrapper">
        <i class="icon">date_range</i>
      </div>
      <Datepicker
        v-model="startValue"
        class="dateRange__field--picker"
        :lowerLimit="startLowerLimit"
        :upperLimit="startUpperLimit"
        :inputFormat="inputFormat"
        data-cy="start-date-picker"
      />
      <div class="dateRange__divider"></div>
      <Datepicker
        v-model="endValue"
        class="dateRange__field--picker"
        :lowerLimit="endLowerLimit"
        :upperLimit="endUpperLimit"
        :inputFormat="inputFormat"
        data-cy="end-date-picker"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';

export default {
  name: 'TpgDateRange',
  components: {
    Datepicker
  },
  emits: ['update:startDate', 'update:endDate'],
  props: {
    startDate: Date,
    endDate: Date,
    startLowerLimit: Date,
    startUpperLimit: Date,
    endLowerLimit: Date,
    endUpperLimit: Date,
    inputFormat: String
  },
  computed: {
    startValue: {
      get() {
        return this.startDate;
      },
      set(newValue) {
        this.$emit('update:startDate', newValue);
      }
    },
    endValue: {
      get() {
        return this.endDate;
      },
      set(newValue) {
        this.$emit('update:endDate', newValue);
      }
    }
  }
};
</script>

<style lang="scss">
.dateRange {
  &__field {
    position: relative;
    border-radius: 4px;
    border: 1px #d1d5db solid;
    background-color: var(--white);
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 16px;
    width: fit-content;

    &--picker {
      height: 39px;
      display: inline;
      width: 80px;
      color: var(--assess-text-color);
      font-weight: var(--font-weight-bold);
      font-family: var(--font-family-default);
      border: none;
      border-radius: 4px;
      background-color: var(--transparent);
      cursor: pointer;
      text-align: center;

      &:focus-visible {
        outline: none;
      }
    }

    & i.icon {
      margin-top: 3px;
      cursor: pointer;
      pointer-events: none;
    }

    &--iconWrapper {
      color: var(--assess-text-color);
      background-color: var(--white);
    }
  }

  &__divider {
    width: 1px;
    height: 16px;
    background-color: #ededed;
  }
}
</style>
