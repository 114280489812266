<template>
  <span class="avatar" :class="size" :title="showName ? undefined : fullName">
    <div class="avatar__initials">{{ initials(agent) }}</div>
    <span v-if="showName" class="avatar__fullName">{{ showLastNameFirst ? lastFirst(agent) : fullName(agent) }}</span>
  </span>
</template>

<script>
import { initials, fullName, lastFirst } from '@/utils/name-helpers';

export default {
  // eslint-disable-next-line
  name: 'Avatar',
  data: () => ({
    initials,
    fullName,
    lastFirst
  }),
  props: {
    agent: Object,
    showName: Boolean,
    showLastNameFirst: Boolean,
    size: {
      // 'xsmall' | 'small' | 'medium' | 'large'
      type: String,
      default: 'small'
    }
  }
};
</script>

<style lang="scss">
.avatar {
  color: var(--assess-text-accent-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--assess-avatar-font-size);
  display: flex;
  align-items: center;
  gap: 16px;

  &__initials {
    border: var(--avatar-border);
    background-color: var(--avatar-bg-color);
    text-align: center;
    color: var(--avatar-initials-color);
    text-transform: uppercase;
  }

  &.large .avatar__initials {
    height: var(--avatar-size-large);
    line-height: var(--avatar-size-large);
    width: var(--avatar-size-large);
    border-radius: var(--avatar-radius-large);
    font-size: var(--avatar-font-size-large);
  }

  &.medium .avatar__initials {
    height: var(--avatar-size-medium);
    line-height: var(--avatar-size-medium);
    width: var(--avatar-size-medium);
    border-radius: var(--avatar-radius-medium);
    font-size: var(--avatar-font-size-medium);
  }

  &.small .avatar__initials {
    height: var(--avatar-size-small);
    line-height: var(--avatar-size-small);
    width: var(--avatar-size-small);
    border-radius: var(--avatar-radius-small);
    font-size: var(--avatar-font-size-small);
  }

  &.xsmall .avatar__initials {
    height: var(--avatar-size-xsmall);
    line-height: var(--avatar-size-xsmall);
    width: var(--avatar-size-xsmall);
    border-radius: var(--avatar-radius-xsmall);
    font-size: var(--avatar-font-size-xsmall);
  }
}
</style>
