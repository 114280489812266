import { defineStore } from 'pinia';
import { useAgentStore, useTrendStore, useDraftsStore } from '.';
import { AssessmentService } from '@/services/AssessmentService';

export const useDashboardStore = defineStore('dashboard', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    loadingRecentActivity: false,
    recentActivity: [],
    loadingUpcomingAssessments: false,
    upcomingAssessments: []
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Gets relevant data to populate the dashboard.
     * @param {number} agentSourceId (Optional) The ID of the selected agent source.
     */
    hydrateDashboard(agentSourceId, programId) {
      agentSourceId = agentSourceId || useAgentStore().selectedAgentSourceId;
      programId = programId || useAgentStore().selectedProgramId;
      this.getRecentActivity(agentSourceId, programId);
      useTrendStore().getAllTrends();
      useDraftsStore().getDrafts();
    },
    /**
     * Gets the 3 most recent coaching sessions for an agent source.
     * @param {number} agentSourceId (Optional) The ID of the selected agent source.
     * @returns {AxiosResponse}
     */
    getRecentActivity(agentSourceId, programId) {
      this.loadingRecentActivity = true;
      const { selectedAgentSourceId, selectedProgramId } = useAgentStore();
      return AssessmentService.getAssessmentsByAgentSource(
        agentSourceId || selectedAgentSourceId,
        programId || selectedProgramId,
        1,
        3
      )
        .then(response => {
          this.recentActivity = response.data?.items;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        })
        .finally(() => {
          this.loadingRecentActivity = false;
        });
    }
  }
});
