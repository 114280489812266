<template>
  <div class="evalPlayer">
    <div
      id="waveform"
      class="evalPlayer__waveformContainer"
      @mouseenter="showScrubber()"
      @mouseleave="hideScrubber()"
      @mousemove="moveScrubber"
      @click="allowComments ? handleWaveformClick($event) : null"
    >
      <img :src="require('../../assets/media_player.png')" />
      <div ref="scrubber" class="evalPlayer__scrubber"></div>
      <div
        v-for="(comment, i) in activeEvalComments"
        :key="i"
        class="evalPlayer__commentMarker"
        :class="{ anna: comment.anna || comment.isDefect, nominal: comment.isDefect === false }"
        :style="`left:${calcMarkerPosition(comment.timeInMedia)}`"
      ></div>
    </div>
    <div class="evalPlayer__evalButtons">
      <EvalButton
        v-for="(e, i) in evaluations"
        :class="{ selected: e.evaluationId === activeEvalId }"
        :key="i"
        :evaluation="e"
        @click="selectEvalId(e.evaluationId)"
      />
    </div>
  </div>
</template>

<script>
import EvalButton from '../AssessmentSteps/Behaviors/EvalButton.vue';
import { prettyTimeString, convertPrettyTimeToSeconds } from '@/utils/time.js';

export default {
  name: 'EvalPlayer',
  components: {
    EvalButton
  },
  props: {
    evaluations: Array,
    comments: Array,
    allowComments: {
      type: Boolean,
      default: false
    }
  },
  emits: ['addComment'],
  data: () => ({
    rect: null,
    activeEvalId: null
  }),
  computed: {
    activeEval() {
      return this.evaluations.find(e => e.evaluationId === this.activeEvalId);
    },
    activeEvalComments() {
      return this.comments.filter(c => c.evaluationId === this.activeEvalId) || null;
    },
    activeEvalMediaDuration() {
      // Random number in seconds to use as media duration.
      // TODO: get real media duration.
      return 197.256;
    }
  },
  methods: {
    selectEvalId(id) {
      this.activeEvalId = id;
    },
    handleWaveformClick(e) {
      const duration = this.activeEvalMediaDuration;
      const secondsClicked = duration * ((e.clientX - this.rect.x) / this.rect.width);
      const timeInMedia = prettyTimeString(secondsClicked);
      this.$emit('addComment', { timeInMedia, evaluationId: this.activeEvalId });
    },
    showScrubber() {
      this.$refs.scrubber.style.opacity = 1;
    },
    hideScrubber() {
      this.$refs.scrubber.style.opacity = 0;
    },
    moveScrubber(e) {
      const position = `${e.clientX - this.rect.x - 3}px`;
      this.$refs.scrubber.style.left = position;
    },
    saveClientRect() {
      this.rect = document.getElementById('waveform').getBoundingClientRect();
    },
    calcMarkerPosition(timeStamp) {
      const seconds = convertPrettyTimeToSeconds(timeStamp);
      // -10 is offset for center of 21px circle.
      return `${(seconds * this.rect.width) / this.activeEvalMediaDuration - 10}px`;
    }
  },
  mounted() {
    this.activeEvalId = this.evaluations[0].evaluationId || null;
    this.saveClientRect();
    window.addEventListener('resize', this.saveClientRect);
  },
  unmounted() {
    window.removeEventListener('resize', this.saveClientRect);
  }
};
</script>

<style lang="scss">
.evalPlayer {
  &__waveformContainer {
    height: 96px;
    width: 100%;
    border-top: 1px #bad5ff solid;
    border-bottom: 1px #bad5ff solid;
    background-color: #d9e8ff;
    position: relative;

    & img {
      pointer-events: none;
    }
  }

  &__scrubber {
    opacity: 0;
    width: 5px;
    box-shadow: 0 0 4px 4px rgba(240, 205, 131, 0.6);
    background-color: rgba(255, 167, 63, 0.6);
    position: absolute;
    top: -4px;
    bottom: -4px;
    border-radius: 2.5px;
    z-index: 2;
  }

  &__commentMarker {
    position: absolute;
    z-index: 10;
    top: 39px;
    height: var(--comment-wave-ind-size);
    width: var(--comment-wave-ind-size);
    background-color: var(--comment-ind-bg-color-trans);
    border: var(--comment-ind-border);
    border-radius: var(--comment-wave-ind-border-radius);

    &.anna {
      background-color: var(--comment-anna-ind-bg-color-trans);
      border: var(--comment-anna-ind-border);
    }

    &.nominal {
      background: rgba(109, 250, 241, 0.8);
      border: 1px solid var(--success);
    }
  }

  &__evalButtons {
    margin-top: 16px;
    display: flex;
    gap: 16px;
  }
}
</style>
