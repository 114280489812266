<template>
  <div class="coachingMethods">
    <Checkbox
      v-for="(method, i) of options"
      :key="i"
      class="coachingMethods__checkbox"
      :checked="isSelected(method.coachingMethodId)"
      @click="toggleCoachingMethod(method.coachingMethodId)"
      :label="method.description"
      data-cy="coaching-method-checkbox"
    />
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'CoachingMethodSelection',
  components: {
    Checkbox
  },
  props: {
    options: Array,
    modelValue: Array
  },
  emits: ['update:modelValue'],
  methods: {
    isSelected(id) {
      return this.modelValue.includes(id);
    },
    toggleCoachingMethod(id) {
      const clone = [...this.modelValue];
      const index = clone.indexOf(id);
      if (index > -1) {
        clone.splice(index, 1);
      } else {
        clone.push(id);
      }
      this.$emit('update:modelValue', clone);
    }
  }
};
</script>

<style lang="scss">
.coachingMethods {
  max-height: 544px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
</style>
