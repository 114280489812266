<template>
  <BaseDialog v-model="show" v-bind="$attrs" :click-to-close="false">
    <template v-slot:title>{{ options.title }}</template>
    <template v-slot:content>
      <div class="confimDialog__content">
        <template v-if="Array.isArray(options.message)">
          <p v-for="(sentence, i) in options.message" :key="i">{{ sentence }}</p>
        </template>
        <p v-else>{{ options.message }}</p>
      </div>
    </template>
    <template v-slot:actions>
      <TpgButton flat @click="confirmStore.cancel" data-cy="cancel-button">{{ options.cancelText }}</TpgButton>
      <TpgButton :class="options.confirmButtonStyle" @click="confirmStore.confirm" data-cy="confirm-button">
        {{ options.confirmText }}
      </TpgButton>
    </template>
  </BaseDialog>
</template>

<script setup>
import { BaseDialog, TpgButton } from 'vue-components';
import { storeToRefs } from 'pinia';
import { useConfirmDialogStore } from '@/stores';

const confirmStore = useConfirmDialogStore();
const { show, options } = storeToRefs(confirmStore);
</script>

<style lang="scss">
.confimDialog__content {
  max-width: 450px;
  margin: 32px 0;
}
.delete {
  background-color: var(--error) !important;
}
</style>
