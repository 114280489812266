import { defineStore } from 'pinia';

export const useDocStore = defineStore('doc', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    appPrefix: 'Develop |'
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    /**
     * Updates the document title in the browser tab to reflect the name of the page the user is on.
     * @param {String} title The name of the page the user is viewing.
     */
    updateDocTitle(title) {
      document.title = `${this.appPrefix} ${title}`;
    }
  }
});
