import { HttpService as http } from './HttpService';
import { URLS } from '@/resources';

/**
 * Service class for interfacing with behavior related endpoints.
 */
export class BehaviorService {
  /**
   * Gets all focus behaviors for a given agent.
   * @param {number} agentId The agent ID
   * @param {number} agentSourceId The selected agent source ID
   * @param {number} programId The selected program ID
   * @returns {AxiosResponse} A list of site (enterprise) focus behaviors, individual focus behaviors (not used), and general skills
   */
  static getFocusBehaviors(agentId, agentSourceId, programId) {
    return http.get(URLS.FOCUS_BEHAVIOR, { params: { agentId, agentSourceId, programId } });
  }

  /**
   * Gets all compendium behaviors for the program.
   * @param {number} programId The selected program ID
   * @returns {AxiosResponse} A list of compendium behaviors
   */
  static getCompendiumBehaviors(programId) {
    return http.get(URLS.BEHAVIOR, { params: { programId } });
  }

  /**
   * Gets all call outcomes for the program.
   * @param {number} programId The selected program ID
   * @returns {AxiosResponse} A list of call outcomes
   */
  static getCallOutcomes(programId) {
    return http.get(URLS.CALL_OUTCOME, { params: { programId } });
  }

  /**
   * Gets a list of behaviors that are marked as Enterprise Coaching Focuses for a program. "Site" term used for brevity.
   * @param {number} agentSourceId Agent Source Id to get site behaviors for
   * @param {number} programId Program Id to get site behaviors for
   * @returns {AxiosResponse} A list of behaviors
   */
  static getSiteFocusBehaviors(agentSourceId, programId) {
    return http.get(URLS.SITE_FOCUS_BEHAVIOR, { params: { agentSourceId, programId } });
  }

  /**
   * Adds a site focus to a program.
   * @param {Object} siteFocus A siteFocus to add (includes programId and agentSourceId)
   * @returns {AxiosResponse}
   */
  static addSiteFocusBehavior(siteFocus) {
    return http.post(URLS.SITE_FOCUS_BEHAVIOR, siteFocus);
  }

  /**
   * Deactivates a site focus from its associated program.
   * @param {number} siteFocusBehaviorId The id of the siteFocus to remove.
   * @returns {AxiosResponse}
   */
  static removeSiteFocusBehavior(siteFocusBehaviorId) {
    return http.put(URLS.SITE_FOCUS_BEHAVIOR, {}, { params: { siteFocusBehaviorId } });
  }
}
