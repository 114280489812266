<template>
  <tr v-for="i in [1, 2, 3]" :key="i">
    <td>
      <div class="round"></div>
      <div class="rect"></div>
    </td>
    <td><div class="rect"></div></td>
    <td><div class="rect"></div></td>
  </tr>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
tr {
  background-color: var(--white);
  border: 1px var(--primary-dark) solid;

  & td:nth-child(1) {
    padding: 16px 0px 16px 24px;
  }

  & td {
    padding: 16px 0px;
    vertical-align: middle;

    & .round {
      display: inline-block;
      height: 28px;
      width: 28px;
      border-radius: 16px;
      background-color: #ddd;
    }

    & .rect {
      display: inline-block;
      height: 18px;
      margin: 5px 8px;
      width: 200px;
      background: linear-gradient(to right, #ddd 20%, #eee 50%, #ddd 80%);
      animation: shiny-rect 4s linear infinite;
    }
  }

  & td:last-child {
    padding: 16px 24px 16px 0;
  }
}

@keyframes shiny-rect {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
</style>
