import { useAgentStore, useAuthStore } from '@/stores';

/**
 * Disallows access to the app if the user in not authenticated.
 * Redirects to login if not authed.
 * @returns {boolean}
 */
export const authGuard = async () => {
  const authStore = useAuthStore();
  const creds = await authStore.getTokens();
  return !!creds;
};

/**
 * Redirects the user to the agent source selection page if they have not selected an agent source.
 * @returns {RouteLocation | undefined}
 */
export const agentSourceGuard = async to => {
  const agentStore = useAgentStore();
  const redirect = { name: 'AgentSourceSelection' };
  if (to.fullPath) {
    redirect.query = { returnUrl: encodeURIComponent(to.fullPath) };
  }

  // Program ID is in query parameter
  if (to.query?.programId && to.params.agentId) {
    const agent = await agentStore.getAgentById(to.params.agentId);

    if (agent.agentSourceId) {
      agentStore.setSelectedIds({
        agentSourceId: agent.agentSourceId,
        programId: Number(to.query.programId)
      });
    }
  }

  return agentStore.selectedAgentSourceId && agentStore.selectedProgramId ? undefined : redirect;
};

/**
 * Allows the user to enter Admin accessible pages or returns user to the dashboard.
 * @returns {boolean}
 */
export const adminGuard = () => {
  return useAuthStore().checkPermission('Coaching', 'admin') || { name: 'ManagerDash' };
};
