/**
 * Create the auth iframe, set the id and src, and configure to load the Vue app after iframe is loaded.
 */
export const liftoff = (iframeId, srcUrl, initApp) => {
  const launchpad = document.createElement('iframe');
  launchpad.onload = () => initApp();
  launchpad.id = iframeId;
  launchpad.src = srcUrl;
  launchpad.style = 'display: none;';
  document.body.appendChild(launchpad);
};
